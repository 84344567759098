export const projects = [
    {
        title: "Portfolio",
        type: "Projet personnel",
        status: "primary",
        cover: "Portfolio.png",
        link: "/",
        resume: "Première version de mon portfolio réalisé en parallèle de mes études. Ce projet avait pour objectifs de me créer une première présence sur le web et de réaliser une plateforme sur laquelle regrouper et exposer mon travail publiquement. Il a été réalisé en React dans un but formateur.",
        technologies: ["React", "HTML", "Sass"],
    },
    {
        title: "Morpion",
        type: "Projet personnel",
        status: "secondary",
        cover: null,
        link: "https://play.google.com/store/apps/details?id=com.biskotte.morpion&gl=FR",
        resume: "Morpion multijoueur en local sur mobile.",
        technologies: ["Flutter"],
    },
    {
        title: "Ideas",
        type: "Projet personnel",
        status: "secondary",
        cover: null,
        link: "https://play.google.com/store/apps/details?id=com.biskotte.ideas&gl=FR",
        resume: "Petite application mobile pour noter ses idées rapidement.",
        technologies: ["Flutter"],
    },
    {
        title: "CV Lucifer",
        type: "Projet scolaire",
        status: "secondary",
        cover: null,
        link: "https://biskotte.fr/cv/index.html",
        resume: "CV fictif du personnage de série Lucifer Morningstar.",
        technologies: ["HTML", "CSS"],
    },
];
